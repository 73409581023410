<template>
  <div class="app-header">
    <div class="inside flex">
      <div class="left flex">
        <a href="/" class="logo"><img src="/static/logo-dark.svg" /></a>
        <div v-if="cloudType" class="cloud-logo">
          <img :src="`/static/${cloudType}-logo.svg`" />
        </div>
      </div>
      <div class="center flex">
        <h1 class="h1">Cloud Deployment Tool</h1>
      </div>
      <div class="right flex">
        <div v-if="docs" class="assistance flex">
          <p>
            <a :href="`${docs.video}`" target="_blank" rel="noopener">Video</a>
          </p>
          <v-divider vertical />
          <p>
            <a :href="`${docs.url}`" target="_blank" rel="noopener"> Documentation </a>
          </p>
          <v-divider vertical />
        </div>
        <p>
          Need Help?
          <a href="https://community.exasol.com/t5/support/ct-p/Support" target="_blank" rel="noopener">Contact us</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getDocSource } from './constants';

export default {
  name: 'AppHeader',
  props: ['cloudType'],
  computed: {
    docs() {
      return getDocSource(this.cloudType);
    },
  },
};
</script>

<style>
.app-header,
.flex {
  display: flex;
  align-items: center;
}

.app-header {
  padding: 0 15px;
}

.app-header h1 {
  font-size: 16px;
  margin-bottom: 0;
}

.cloud-logo,
.right {
  display: none;
}

.left a {
  display: flex;
}

.app-header {
  height: 72px;
  background: #fff;
  box-shadow: 1px 1px 2px rgba(9, 62, 82, 0.08), 2px 2px 30px rgba(9, 62, 82, 0.05);
}

.logo img {
  height: 20px;
}

.center {
  justify-content: flex-end;
}

@media (min-width: 960px) {
  .app-header {
    padding: 0 40px;
  }
  .app-header h1 {
    font-size: 18px;
  }
  .app-header p {
    margin-bottom: 0;
  }
  .logo img,
  .cloud-logo img {
    height: 23px;
  }
  .cloud-logo {
    height: 32px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #ccc;
    display: flex;
    align-items: center;
  }
  .left,
  .center,
  .right {
    width: 38%;
    display: flex;
  }
  .center {
    width: 24%;
  }
  a.nounderline {
    text-decoration: none !important;
  }
  .center {
    justify-content: center;
  }
  .assistance,
  .right {
    justify-content: flex-end;
  }
  .assistance .v-divider {
    margin: 0 10px;
  }
  .right p {
    font-size: 13px;
  }
  .right a {
    color: var(--text);
    font-weight: 600;
    text-decoration: underline;
  }
  .right a:hover {
    color: var(--blue-hover);
  }
  .right {
    display: flex;
  }
}
</style>
