<template>
  <div class="summary-row">
    <div class="summary-title">
      <p v-if="title">{{ title }}</p>
    </div>
    <div class="summary-value">
      <p v-if="value">{{ value }}</p>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SummaryRow',
  props: ['title', 'value', 'childValue'],
};
</script>

<style scoped>
.summary-row {
  display: flex;
  text-align: left;
  padding: 5px 30px;
  border: 1px solid var(--border);
  border-bottom: none;
  border-top: none;
}

.summary-row p {
  margin: 0;
}
.summary-row:first-child {
  border-top: 1px solid var(--border);
  border-radius: 10px 10px 0 0;
  padding-top: 30px;
}

.summary-row:last-child {
  margin-bottom: 0;
  background: var(--light-green);
  border: 1px solid var(--green);
  border-radius: 0 0 10px 10px;
  padding-top: 20px;
  padding-bottom: 30px;
}

.summary-row:last-child .summary-title {
  border-color: var(--green);
}
.summary-title,
.summary-value {
  padding-top: 8px;
  padding-bottom: 8px;
}
.summary-title {
  width: 120px;
  border-right: 2px solid var(--border);
  padding-right: 10px;
}
.summary-title p {
  font-weight: 700;
  line-height: 1.2em;
}
.summary-value {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
