<template>
  <SummaryFrame :full="true" :cluster_model="advanced_view ? cluster_model_advanced : cluster_model">
    <ConfigBasic
      v-if="!advanced_view"
      :cluster_model="cluster_model"
      :advanced_view="advanced_view"
      :config-is-available="configIsAvailable"
      :set-cluster-model="setClusterModel"
    />

    <ConfigAdvanced
      v-if="advanced_view"
      :platform_path="platform_path"
      :cluster_model="cluster_model_advanced"
      :performance_level="performance_level"
      :switch_to_advanced_configurator="switch_to_advanced_configurator"
      :advanced_view="advanced_view"
      :instance_type_changed="instance_type_changed"
      :helper_sections="helper_sections"
      :change_instance_family="change_instance_family"
      :description_instance_families="description_instance_families"
      :change_group="change_group"
      :raw-data-exceeded="rawDataExceeded"
      :set-cluster-model="setClusterModel"
    />
  </SummaryFrame>
</template>

<script>
import SummaryFrame from '../SummaryFrame';
import ConfigAdvanced from './ConfigAdvanced';
import ConfigBasic from './ConfigBasic';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Config',

  components: {
    SummaryFrame,
    ConfigBasic,
    ConfigAdvanced,
  },
  props: [
    'cluster_model',
    'cluster_model_advanced',
    'advanced_view',
    'configIsAvailable',
    'setClusterModel',
    'platform_path',
    'performance_level',
    'switch_to_advanced_configurator',
    'instance_type_changed',
    'helper_sections',
    'change_instance_family',
    'description_instance_families',
    'change_group',
    'rawDataExceeded',
  ],
};
</script>

<style scoped></style>
