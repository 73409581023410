<template>
  <div class="bottom">
    <v-btn v-if="step > 1" flat class="no-texttransform" @click="_setStep(true)"> Back </v-btn>
    <span v-if="step == 1"> </span>
    <v-btn v-if="step < 3" color="primary" :disabled="nextDisabled" class="no-texttransform" @click="_setStep()">
      Continue
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ButtonRow',
  props: ['step', 'cluster_model', 'advanced_view', 'rawDataExceeded'],
  computed: {
    nextDisabled: function () {
      if (Number(this.step) > 0) {
        return this.cluster_model.cloudplatform == null;
      }
      if (Number(this.step) > 2) {
        // if (!this.advanced_view) {
        //   return !(
        //     (this.cluster_model.size != null &&
        //       this.cluster_model.budget != 'Custom' &&
        //       this.cluster_model.budget != null) ||
        //     this.cluster_model.budget == 'Custom'
        //   );
        // } else {
        //   return (
        //     this.cluster_model.instance_type == null || this.cluster_model.instance_type == '' || !this.rawDataExceeded
        //   );
        // }
      }
      return false;
    },
  },
  methods: {
    _setStep: function (back) {
      let current = this.step;
      current = back ? Number(this.step) - 1 : Number(this.step) + 1;
      if (current < 1) current = 1;
      if (current > 3) current = 3;
      // this.step = current;
      this.$emit('setStep', current);
    },
  },
};
</script>
