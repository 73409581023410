<template>
  <v-app>
    <v-toolbar color="primary" app></v-toolbar>
    <v-content color="primary" app>
      <v-container fluid>
        {{ msg }}
      </v-container>
    </v-content>
    <v-footer color="secondary" app>
      <v-spacer></v-spacer>
      <div>&copy; Exasol Cloud UI {{ new Date().getFullYear() }}</div>
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'ComponentNotFound',
  data() {
    return {
      msg: 'RouteNotFound',
    };
  },
};
</script>

<style scoped></style>
