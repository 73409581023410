<!-- eslint-disable vue/no-mutating-props -->
<template>
  <SectionContainer>
    <v-layout row wrap>
      <AlertBox :title="'This tool is deprecated'" :theme="'warning'">
        <p>
          This tool only supports
          <a ref="noopener" target="_blank" href="https://docs.exasol.com/db/7.1/home.htm">version 7.1</a> and is no
          longer being actively developed.
        </p>
        <p>
          We recommend installing our most recent version 8 (<a
            target="_blank"
            href="https://docs.exasol.com/db/latest/get_started.htm"
            rel="noopener"
            >see Documentation</a
          >).
          <br />
          The most convenient way to use or evaluate Exasol 8 is our
          <a target="_blank" href="https://www.exasol.com/exasol-saas/" rel="noopener">SaaS offering</a>
          with Pay-as-you-go pricing.
        </p>
        <p>
          If you still plan to use Exasol version 7.1 on Azure,GCP or AWS please
          <a target="_blank" href="https://exasol.my.site.com/s/create-new-case?language=en_US" rel="noopener"
            >contact our support team</a
          >
          first to be informed about any potential limitations that might affect your usage as well as alternative
          options.
        </p>
      </AlertBox>

      <!--<AlertBox :title="'Exasol Cloud Deployment'">
        <p>
          Install an Exasol Cluster on your cloud platform.
          <a target="_blank" :href="platformLink" rel="noopener">Learn more</a>.
        </p>
      </AlertBox>-->

      <v-flex
        v-if="
          !(platform_path || '').includes('aws') &&
          !(platform_path || '').includes('azure') &&
          !(platform_path || '').includes('google')
        "
        xs12
        mt-5
      >
        <h3>Select your cloud platform</h3>
      </v-flex>

      <v-layout row wrap>
        <Card
          v-if="!(platform_path || '').includes('azure') && !(platform_path || '').includes('google')"
          class="platform-card"
          hover
          :class="cluster_model.cloudplatform == 'amazon' ? 'card-active' : ''"
          @click.native="change_cloud_region('amazon')"
        >
          <img src="/static/aws-logo.svg" width="40%" style="margin: auto" />
          <v-card-text class="subheader">AWS</v-card-text>
        </Card>

        <Card
          v-if="!(platform_path || '').includes('aws') && !(platform_path || '').includes('google')"
          class="platform-card"
          hover
          :class="cluster_model.cloudplatform == 'azure' ? 'card-active' : ''"
          @click.native="change_cloud_region('azure')"
        >
          <img src="/static/azure-logo.svg" width="40%" style="margin: auto" />
          <v-card-text class="subheader">Azure</v-card-text>
        </Card>

        <Card
          v-if="!(platform_path || '').includes('azure') && !(platform_path || '').includes('aws')"
          class="platform-card"
          hover
          :class="cluster_model.cloudplatform == 'google' ? 'card-active' : ''"
          @click.native="change_cloud_region('google')"
        >
          <img src="/static/gcp-logo.svg" width="40%" style="margin: auto" />
          <!-- filter: grayscale(100%); -->
          <v-card-text class="subheader">Google Cloud</v-card-text>
        </Card>
      </v-layout>
    </v-layout>

    <v-layout row wrap mt-4>
      <v-flex v-show="cluster_model.cloudplatform != null" xs12 pr-2 pt-4>
        <h3>Select your region</h3>
      </v-flex>
      <v-flex v-show="cluster_model.cloudplatform != null" class="region-container">
        <v-select
          v-model="cluster_model.region"
          name="region-name"
          solo
          :items="cluster_model.regions"
          item-text="name"
          item-value="key"
          return-object
        ></v-select>
        <p>Please note that some options might (e.g. VM models) might not be available in all regions.</p>
      </v-flex>
    </v-layout>
  </SectionContainer>
</template>

<script>
import { defaultClusterModel, getDocSource } from '../constants';
import sizing_selection from '@/assets/sizing_selection.json';
import SectionContainer from '../SectionContainer';
import AlertBox from '@/components/common/AlertBox';
import Card from '@/components/common/Card';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Platform',
  components: { SectionContainer, AlertBox, Card },
  props: ['platform_path', 'cluster_model', 'setCloudType', 'cloudType'],
  computed: {
    platformLink() {
      const cloudLink = (getDocSource(this.cloudType) || {}).url;
      return cloudLink || 'https://docs.exasol.com/get_started.htm';
    },
  },
  methods: {
    change_cloud_region(cloudplatform) {
      if (this.cluster_model.cloudplatform != cloudplatform) {
        const cm = Object.assign({}, defaultClusterModel);
        cm.cloudplatform = cloudplatform;
        cm.regions = sizing_selection[cm.license][cloudplatform]['regions'];
        cm.region = cm.regions[0];
        cm.instance_families = Object.keys(sizing_selection[cm.license][cloudplatform]['instances']);
        // If you change the management instance type, please update utils/crosscheck.mjs to check if the values are available in all regions
        cm.instance_type_mgmt =
          cm.cloudplatform == 'amazon'
            ? 'm5.large'
            : cm.cloudplatform == 'google'
            ? 'n1-standard-2'
            : 'Standard_E2s_v3';

        this.$emit('setClusterModel', cm);
      }
    },
  },
};
</script>

<style scoped>
.platform-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  width: 100%;
  height: 160px;
  margin-right: 20px;
  box-shadow: none;
  border: 1px solid var(--border);
  border-radius: var(--border-radius);
  margin-bottom: 15px;
  text-align: center;
  justify-content: center;
}
.platform-card img {
  height: 60px;
  margin: 0 auto !important;
}

.platform-card .v-card__text {
  padding: 0;
  margin-top: 10px;
  font-weight: 600;
}

@media (min-width: 600px) {
  .platform-card {
    width: 140px;
  }
}

@media (min-width: 850px) {
  .platform-card {
    width: 240px;
  }
}

.card-active {
  background-color: var(--blue-faded);
  border-color: var(--blue);
}
.card-active:after {
  content: '';
  position: absolute;
  top: 10px;
  right: 10px;
  background: url(/public/static/check_circle.svg) center no-repeat;
  width: 20px;
  height: 20px;
}

.region-container {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.region-container .v-input {
  margin-bottom: 10px;
  width: 100%;
}

a,
a b {
  color: var(--blue-cloud);
  text-decoration: underline;
}

a:hover,
a b:hover {
  color: var(--blue-cloud-hover);
}
</style>
