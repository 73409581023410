<template>
  <div class="app-container">
    <v-container>
      <slot></slot>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'AppContainer',
};
</script>

<style scoped>
.app-container {
  height: 100%;
  padding: 0;
}

.app-container > .container {
  background: #fff;
  height: calc(100% - 100px);
  box-shadow: 0px 14px 45px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin: 40px auto 60px;
  padding: 0;
  overflow: hidden;
}
</style>
