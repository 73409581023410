<template>
  <div class="col2">
    <Card
      v-for="(n, i) in cluster_model.instance_families"
      :key="n"
      hover
      :class="n == cluster_model.instance_family ? 'active' : ''"
      @click.native="changeFamily(n)"
    >
      <h4>{{ n }}</h4>

      <p v-show="helper_sections.family">
        {{ description_instance_families[i] }}
      </p>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/common/Card';
export default {
  name: 'InstanceFamily',
  components: {
    Card,
  },
  props: [
    'helper_sections',
    'change_instance_family',
    'cluster_model',
    'description_instance_families',
    'raw_data_to_resource_alignment',
  ],
  methods: {
    changeFamily(n) {
      // eslint-disable-next-line vue/no-mutating-props
      this.change_instance_family(this.cluster_model, n);
      this.raw_data_to_resource_alignment(this.cluster_model);
    },
  },
};
</script>
