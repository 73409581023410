import amazon_instances from '../assets/assetscloud/amazon_instances.json';
import azure_instances from '../assets/assetscloud/azure_instances.json';
import google_instances from '../assets/assetscloud/google_instances.json';

export default {
  hasInstanceModel(cloud_platform, regionkey, instance_name) {
    let source;

    switch (cloud_platform) {
      case 'amazon':
        source = amazon_instances;
        break;
      case 'azure':
        source = azure_instances;
        break;
      case 'google':
        source = google_instances;
    }
    return source[regionkey][instance_name] !== undefined;
  },
  get_vm_metadata(cloud_platform, regionkey, instance_name) {
    let overview = {
      cpu: 0,
      memory: 0,
      price: 0,
    };

    let source;

    switch (cloud_platform) {
      case 'amazon':
        source = amazon_instances;
        break;
      case 'azure':
        source = azure_instances;
        break;
      case 'google':
        source = google_instances;
    }

    if (source) {
      overview.cpu = ((source[regionkey] || {})[instance_name] || {})['cpu'];
      overview.memory = ((source[regionkey] || {})[instance_name] || {})['memory'];
      overview.price = ((source[regionkey] || {})[instance_name] || {})['price'];
    }

    return overview;
  },
};
