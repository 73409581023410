<template>
  <div class="options">
    <p>
      Start with adding the size of your data, choose a license model, and then specify select the details to optimize
      your memory and compute needs.
    </p>

    <p>
      Not sure where to start? Load our
      <a @click="loadRec">recommended configuration</a>.
    </p>

    <v-expansion-panel v-model="panelStates" class="option-panels" focusable>
      <v-expansion-panel-content
        v-for="(key, i) in panelKeys"
        :key="i"
        :value="panelKeys.open"
        :readonly="key.noexpand"
        :class="key.noexpand ? 'no-expand' : ''"
      >
        <template #header>
          <h3>{{ key.name }}</h3>
          <div
            :is="key.component"
            v-if="key.noexpand"
            :raw-data-exceeded="rawDataExceeded"
            :cluster_model="cluster_model"
            :raw_data_to_resource_alignment="raw_data_to_resource_alignment"
            :helper_sections="helper_sections"
            :instance_type_changed="instance_type_changed"
            :description_instance_families="description_instance_families"
            :change_instance_family="change_instance_family"
            :change_group="change_group"
            :set-cluster-model="setClusterModel"
          />
          <p v-if="!key.noexpand">{{ getSummary(key, i) }}</p>
        </template>
        <v-card>
          <div class="card-content">
            <div
              :is="key.component"
              :raw-data-exceeded="rawDataExceeded"
              :cluster_model="cluster_model"
              :raw_data_to_resource_alignment="raw_data_to_resource_alignment"
              :helper_sections="helper_sections"
              :instance_type_changed="instance_type_changed"
              :description_instance_families="description_instance_families"
              :change_instance_family="change_instance_family"
              :change_group="change_group"
              :set-cluster-model="setClusterModel"
            />
          </div>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>
<script>
import Summary from '@/components/common/Summary';
import SectionContainer from '../../SectionContainer';
//import RawData from './RawData';
import License from './License';
import System from './System';
import InstanceFamily from './InstanceFamily';
import InstanceType from './InstanceType';
import InstanceModel from './InstanceModel';
import Nodes from './Nodes';
import sizing_wizard_input from '@/assets/sizing_wizard_input.json';
import resource_metadata_service from '@/service/resource-metadata-service';

const panelKeys = [
  // {
  //   name: 'Raw Data Size (in TB)',
  //   component: RawData,
  //   open: true,
  //   noexpand: true,
  // },
  { name: 'License Model', component: License },
  { name: 'System Type', component: System },
  { name: 'Instance Family', component: InstanceFamily },
  { name: 'Instance Type', component: InstanceType, noexpand: true },
  { name: 'Instance Model', component: InstanceModel, noexpand: true },
  { name: 'Number of DB Nodes', component: Nodes, noexpand: true },
];

export default {
  name: 'ConfigAdvanced',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Summary,
    SectionContainer,
  },
  props: [
    'platform_path',
    'cluster_model',
    'performance_level',
    'switch_to_advanced_configurator',
    'instance_type_changed',
    'helper_sections',
    'change_instance_family',
    'description_instance_families',
    'change_group',
    'rawDataExceeded',
    'setClusterModel',
  ],
  data() {
    return {
      panelKeys: panelKeys.map((it) => {
        it.props = this.props;
        return it;
      }),
      panelStates: panelKeys.map((it) => it.open),
    };
  },
  methods: {
    // NEW
    togglePanel(idx, bool = true) {
      this.panelStates = this.panelStates.map((p, i) => (i === idx ? bool : p));
    },

    getSummary(key, idx) {
      // if (idx === 0) {
      //   return this.cluster_model.raw_data + 'TB';
      // }
      if (idx === 0) {
        let val = this.cluster_model.license;
        if (val === 'PAYG') val = 'Pay-as-you-go';
        if (val === 'BYOL') val = 'Bring-your-own-license';
        return val;
      }
      if (idx === 1) {
        return this.cluster_model.product === 'exasol_single_node' ? 'Single Node' : 'Enterprise Cluster';
      }
      if (idx === 2) {
        return this.cluster_model.instance_family;
      }
      if (idx === 3) {
        return this.cluster_model.instance_group;
      }
      if (idx === 4) {
        return (this.cluster_model.instance_type || '').replace('Standard_', '');
      }

      return 'N/A';
    },
    loadRec() {
      const cluster = Object.assign({}, this.cluster_model, {
        raw_data: 1.152,
        license: 'BYOL',
        budget: 'Custom',
        product: 'exasol_cluster',
        instance_family: '',
        instance_group: '',
        instance_type:
          sizing_wizard_input['BYOL']['exasol_cluster'][5]['Balanced'][this.cluster_model.cloudplatform]['name'],
        num_node:
          sizing_wizard_input['BYOL']['exasol_cluster'][5]['Balanced'][this.cluster_model.cloudplatform]['number'],
      });
      this.change_instance_family(cluster, 'General Purpose');

      const instance_type =
        sizing_wizard_input['BYOL']['exasol_cluster'][5]['Balanced'][this.cluster_model.cloudplatform]['name'];
      this.change_group(
        cluster,
        cluster.instance_groups.find((item) => instance_type.startsWith(item))
      );
      cluster.instance_type = instance_type;
      this.raw_data_to_resource_alignment(cluster);

      this.setClusterModel(cluster);
    },

    raw_data_to_resource_alignment(cluster_model) {
      // eslint-disable-next-line vue/no-mutating-props
      cluster_model.budget = 'Custom';
      // eslint-disable-next-line vue/no-mutating-props
      cluster_model.raw_data =
        cluster_model.product == 'exasol_single_node'
          ? ((cluster_model.instance_overview.memory * 10 * 0.7) / 1000).toFixed(2)
          : ((cluster_model.instance_overview.memory * cluster_model.num_node * 10 * 0.9) / 1000).toFixed(2);
      this.instance_type_changed(cluster_model, true);
    },
    raw_data_to_resource_alignment2() {
      if (this.cluster_model.product == 'exasol_single_node') {
        try {
          // eslint-disable-next-line vue/no-mutating-props
          this.cluster_model.instance_type = this.cluster_model.instance_types.filter(
            (x) =>
              resource_metadata_service.get_vm_metadata(
                this.cluster_model.cloudplatform,
                this.cluster_model.region.key,
                x
              )['memory'] *
                0.7 >=
              (this.cluster_model.raw_data * 1000) / 10
          )[0];
          if (typeof this.cluster_model.instance_type == 'undefined' || parseFloat(this.cluster_model.raw_data) <= 0) {
            // eslint-disable-next-line vue/no-mutating-props
            this.rawDataExceeded = false;
          } else {
            this.instance_type_changed(this.cluster_model, true);
            // eslint-disable-next-line vue/no-mutating-props
            this.rawDataExceeded = true;
          }
        } catch (e) {
          // eslint-disable-next-line vue/no-mutating-props
          this.rawDataExceeded = false;
        }
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.cluster_model.num_node = Math.max(
          1,
          Math.round((this.cluster_model.raw_data * 1000) / (10 * 0.9) / this.cluster_model.instance_overview.memory)
        );
        if (this.cluster_model.num_node > 64) {
          // eslint-disable-next-line vue/no-mutating-props
          this.cluster_model.num_node = 64;
          // eslint-disable-next-line vue/no-mutating-props
          this.rawDataExceeded = false;
        } else if (parseFloat(this.cluster_model.raw_data) <= 0) {
          // eslint-disable-next-line vue/no-mutating-props
          this.cluster_model.num_node = 1;
          // eslint-disable-next-line vue/no-mutating-props
          this.rawDataExceeded = false;
        } else {
          this.instance_type_changed(this.cluster_model, true);
          // eslint-disable-next-line vue/no-mutating-props
          this.rawDataExceeded = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.advanced-config {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  margin-top: 50px;
  text-align: left;
}
</style>
