<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="row-container">
    <!-- <Card
      v-for="n in cluster_model.instance_groups"
      :key="n"
      @click.native="change_group(n)"
      hover
      :class="n == cluster_model.instance_group ? 'active' : ''"
    >
      <h4>{{ n }}</h4>
    </Card> -->
    <v-select
      v-model="cluster_model.instance_group"
      :items="cluster_model.instance_groups"
      label="Instance Type"
      solo
      @change="changeType()"
    />
  </div>
</template>

<script>
import Card from '@/components/common/Card';
export default {
  name: 'InstanceType',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Card,
  },
  props: ['cluster_model', 'change_group', 'raw_data_to_resource_alignment'],
  methods: {
    changeType() {
      // eslint-disable-next-line vue/no-mutating-props
      this.change_group(this.cluster_model, this.cluster_model.instance_group);
      this.raw_data_to_resource_alignment(this.cluster_model);
    },
  },
};
</script>

<style scoped>
.row-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.row-container .card {
  width: 100%;
  margin: 0px auto 10px;
}
</style>
