<template>
  <div class="app-footer">
    <v-container>
      <div class="legal-links">
        <a href="https://www.exasol.com/legal-disclosure/" target="_blank" rel="noopener"> Legal Disclosure </a>
        <v-divider vertical></v-divider>
        <a href="https://www.exasol.com/privacy-policy/" target="_blank" rel="noopener"> Privacy Policy </a>
      </div>

      <p>
        Need Help?
        <a
          class="nounderline"
          href="https://community.exasol.com/t5/support/ct-p/Support"
          target="_blank"
          rel="noopener"
        >
          <b>Contact us</b></a
        >
      </p>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
.app-footer .container {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-footer .legal-links {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-footer .v-divider {
  margin: 0 20px;
  border-color: #ccc;
  border-width: 0 thin 0 0;
}

.app-footer {
  height: 98px;
}

a,
a b {
  color: var(--text);
  text-decoration: underline;
}

a:hover,
a b:hover {
  color: var(--blue-hover);
}
</style>
