import { render, staticRenderFns } from "./Platform.vue?vue&type=template&id=04d03e51&scoped=true"
import script from "./Platform.vue?vue&type=script&lang=js"
export * from "./Platform.vue?vue&type=script&lang=js"
import style0 from "./Platform.vue?vue&type=style&index=0&id=04d03e51&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04d03e51",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCardText,VFlex,VLayout,VSelect})
