<template>
  <v-app>
    <appheader :cloud-type="cloudType"></appheader>
    <appcontainer>
      <sizingwizard :cloud-type="cloudType" @setCloudType="setCloudType"></sizingwizard>
    </appcontainer>
    <appfooter></appfooter>
  </v-app>
</template>

<script>
/**
 * TODOs:
 * 1) Check basic and advanced view logic to prevent advancing
 * 2) Finalize the deployment view
 * 3) Add Google Analytics Tracking
 */
import appheader from '@/components/wizard/Header';
import appfooter from '@/components/wizard/Footer';
import sizingwizard from '@/components/wizard/SizingWizard';
import appcontainer from '@/components/wizard/Container';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components: {
    appheader,
    appfooter,
    appcontainer,
    sizingwizard,
  },
  data() {
    return {
      cloudType: null,
    };
  },

  methods: {
    setCloudType: function (type) {
      if (type === 'amazon') type = 'aws';
      if (type === 'google') type = 'gcp';
      this.cloudType = type;
    },
    activate_panel(panel_key) {
      Object.keys(this.panels).forEach((x) => {
        this.panels[x] = x == panel_key ? true : false;
      });
    },
    logout() {
      localStorage.clear();
      this.$router.push('/login');
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&amp;display=swap');
html,
body {
  font-family: 'Inter', sans-serif;
}

#app {
  font-family: 'Inter', sans-serif;
}

:root {
  --blue: #2e8dff;
  --blue-hover: #246abe;
  --blue-light: rgba(33, 150, 243, 0.05);
  --blue-faded: #edf7ff; /* rgba(33, 150, 243, 0.12); */
  --blue-cloud: #0078d4;
  --blue-cloud-hover: #0266b3;
  --text: rgba(18, 45, 78, 0.8);
  --border: #ced8dc;
  --border-radius: 4px;
  --green: #5fc33b;
  --light-green: #e2f4dc;
  --gray-light: #fafafa;
  --gray-bg: #f3faff;

  --theme-warning: #ffecd9;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1em;
}

.v-text-field.v-text-field--solo .v-label,
.v-select__selection--comma,
input,
select,
::placeholder,
ul,
p {
  text-decoration: none;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.3;
  color: var(--text);
}

.v-text-field.v-text-field--solo .v-label {
  opacity: 0.5;
}

li,
p {
  margin: 0.5em 0;
}

a {
  text-decoration: underline;
  color: var(--blue);
}

a:hover {
  color: var(--blue-hover);
}

#app {
  margin-top: 0;
}

.v-btn {
  text-transform: none;
  font-family: Inter, sans-serif;
  outline: 0;
  border: none;
  border-radius: 1.2em;
  height: 2.4em;
  font-weight: 600;
  transition: 0.2s ease;
  position: relative;
  padding: 0.5em 1em;
  line-height: 1.4;
  display: inline-block;
  box-shadow: none !important;
}

.v-btn.primary:not(:disabled) {
  background: #5fc33b;
  color: #fff;
  background: radial-gradient(circle at 50% 50%, #3da432, #83c94e);
  background-size: 200% 200%;
  background-position: 0 0;
}

.v-btn.primary:not(:disabled):hover {
  background-position: -75% 0;
}

.no-expand .v-expansion-panel__header__icon {
  display: none !important;
}

.theme--light.v-expansion-panel
  .v-expansion-panel__container
  .v-expansion-panel__header
  .v-expansion-panel__header__icon
  .v-icon {
  color: var(--blue);
}

/** Inputs */
.v-input input {
  text-align: right;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  display: none;
}
.v-text-field > .v-input__control > .v-input__slot {
  box-shadow: none !important;
  margin: 0;
  border: 1px solid var(--border);
  border-radius: var(--border-radius);
  height: 50px;
}

.v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
  border: 1px solid var(--border) !important;
  border-radius: 6px;
  width: 100%;
  padding: 0 10px 0 15px;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}

.v-expansion-panel__header .v-text-field.v-text-field--enclosed {
  width: 180px !important;
}

.v-text-field.v-text-field--solo .v-label {
  width: 50px;
}

/** Panels */
.v-expansion-panel {
  box-shadow: none;
}

.fake-panel .panel-header h3,
.fake-panel .panel-header h4,
.v-expansion-panel__header h3,
.v-expansion-panel__header h4 {
  margin: 0;
}

.option-panels > li {
  padding: 0;
  margin: 0;
}

.fake-panel {
  border-bottom: 1px solid var(--border);
}
.fake-panel:last-child {
  border-bottom: none;
}

.v-expansion-panel__header,
.fake-panel .panel-header {
  display: flex;
  min-height: 48px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.card-content {
  padding: 0 20px;
}

.fake-panel .panel-header p,
.option-panels li > div:first-child p {
  text-align: right;
  /* color: var(--blue); */
  color: var(--text);
  line-height: 1.4;
  margin-top: 10px;
  margin-right: 10px;
}
.fake-panel .panel-header > *:nth-child(2):not(p),
.option-panels li > div:first-child > *:nth-child(2):not(p) {
  width: 180px;
  display: block;
  flex: none;
}

.theme--light.v-expansion-panel--focusable .v-expansion-panel__container:focus,
.option-panels [aria-expanded='true'] {
  background: var(--gray-light) !important;
}

.fake-panel.expanded,
.option-panels [aria-expanded='true'] .theme--light.v-card {
  background: var(--gray-light) !important;
}

.fake-panel.expanded {
  border-bottom: 1px solid var(--border);
}

.theme--light.v-expansion-panel .v-expansion-panel__container {
  border-color: var(--border);
}

/** spacing */
.col2 {
  display: flex;
  width: 100%;
  flex: 1 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col2 .card {
  width: 48%;
  margin-bottom: 20px;
}
</style>
