<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="row-container">
    <v-select v-model="cluster_model.num_node" :items="nodeArr" label="Nodes" solo @change="setNodes" />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Nodes',
  props: ['cluster_model', 'setClusterModel'],
  data() {
    return {
      nodeArr: [...Array(64).keys()].map((it) => it + 1),
    };
  },
  methods: {
    setNodes(n) {
      // eslint-disable-next-line vue/no-mutating-props
      this.cluster_model.num_node = n;
      this.setClusterModel(this.cluster_model);
    },
  },
};
</script>

<style scoped>
.row-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.row-container .card {
  width: 100%;
  margin: 0px auto 10px;
}
</style>
