import Vue from 'vue';
import './plugins/vuetify';
import App from './App';
import router from './components/wizard/router';

Vue.config.productionTip = false;
Vue.prototype.version = '1.1.9';

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>',
});
