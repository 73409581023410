<template>
  <div v-if="showSummary" class="summary">
    <SummaryRow :title="'Cloud Platform'">
      <component :is="imgComponent" />
    </SummaryRow>
    <SummaryRow :title="'Region'" :value="`${(region || {}).name}`" />
    <SummaryRow :title="'Instance'">
      <p>Type: {{ name }}</p>
      <p>vCPU: {{ cpu }}</p>
      <p>Memory: {{ memory }} GB</p>
      <p>Storage: {{ disks }}</p>
    </SummaryRow>
    <SummaryRow :title="'Cluster'">
      <p v-show="product == 'exasol_cluster'">
        Number of nodes: {{ number }}<br />
        Total amount of memory: {{ number * memory }} GB<br />
        Total amount of vCPUs: {{ number * cpu }}<br />
        Total storage:
        {{ parseFloat(persistent_storage.toFixed(3).toString()) }} TB
      </p>
      <p v-show="product == 'exasol_single_node'">
        <i>This is a single node setup:</i>
        <i>this configuration is neither extensible nor does it provide HA</i>
        <i>(this can be changed in advanced configuration mode)</i>
      </p>
    </SummaryRow>
    <SummaryRow
      :title="'Backup'"
      :value="`Service: ${
        cloudplatform == 'amazon'
          ? 'S3'
          : cloudplatform == 'azure'
          ? 'Azure Storage - Block Blob'
          : 'Google Cloud Storage'
      }`"
    />
    <SummaryRow :title="'Data'">
      <p>Recommended for up to: {{ budget == 'Custom' ? raw_data : size }} TB of raw data</p>
    </SummaryRow>
    <SummaryRow v-show="product == 'exasol_cluster'" :title="'Mgmt. node'">
      <p>Type: {{ name_mgmt }}</p>
    </SummaryRow>

    <SummaryRow :title="'Licence'">
      <p>
        {{ licenceSpelled }}
        <span v-if="license == 'PAYG'"> (with enterprise support)</span><br />
        <i>The license model can be changed in the advanced configuration</i>
      </p>
    </SummaryRow>

    <Costs
      :number="number"
      :budget="budget"
      :size="size"
      :raw_data="raw_data"
      :product="product"
      :license="license"
      :license_price="license_price"
      :price="price"
      :license_price_mgmt="license_price_mgmt"
      :price_mgmt="price_mgmt"
    >
    </Costs>
  </div>
</template>

<script>
import Vue from 'vue';
import Costs from '@/components/common/Costs';
import SummaryRow from '@/components/common/SummaryRow';

// eslint-disable-next-line vue/one-component-per-file
Vue.component('ImgAmazon', {
  template: '<img src="/static/aws-logo.svg" height="30px" />',
});
// eslint-disable-next-line vue/one-component-per-file
Vue.component('ImgAzure', {
  template: `<img src="/static/azure-logo.svg" height="30px" />`,
});
// eslint-disable-next-line vue/one-component-per-file
Vue.component('ImgGoogle', {
  template: `<img src="/static/gcp-logo.svg" height="30px" />`,
});

// eslint-disable-next-line vue/one-component-per-file
export default {
  // eslint-disable-next-line vue/multi-word-component-names, vue/no-reserved-component-names
  name: 'Summary',
  components: {
    Costs,
    SummaryRow,
  },
  props: [
    'region',
    'name',
    'name_mgmt',
    'cpu',
    'memory',
    'number',
    'budget',
    'size',
    'raw_data',
    'product',
    'cloudplatform',
    'license',
    'license_price',
    'price',
    'license_price_mgmt',
    'price_mgmt',
  ],
  computed: {
    licenceSpelled() {
      let val = this.license;
      if (val === 'PAYG') val = 'Pay-as-you-go';
      if (val === 'BYOL') val = 'Bring-your-own-license';
      return val;
    },
    showSummary() {
      return (
        this.cloudplatform != null &&
        ((this.size != null && this.budget != 'Custom' && this.budget != null) || this.budget == 'Custom')
      );
    },
    imgComponent: function () {
      return 'img-' + this.cloudplatform;
    },
    persistent_storage() {
      return this.product == 'exasol_single_node'
        ? this.budget == 'Custom'
          ? this.raw_data * 0.7
          : this.size * 0.7
        : this.budget == 'Custom'
        ? this.raw_data * 1.2
        : this.size * 1.2;
    },
    disks() {
      let storage_capacity_per_node = (this.persistent_storage * 1000) / this.number;
      let disk_string = '';
      let number_disks = 0;

      if (this.cloudplatform == 'amazon') {
        number_disks = Math.max(Math.min(parseInt(storage_capacity_per_node / 334), 10), 1);
        disk_string = ' GB (gp2 EBS)';
      } else if (this.cloudplatform == 'azure') {
        number_disks = Math.max(Math.min(parseInt(storage_capacity_per_node / 256), 9), 1);
        disk_string = ' GB (Premium SSD)';
      } else {
        number_disks = Math.max(Math.min(parseInt(storage_capacity_per_node / 256), 10), 1);
        disk_string = ' GB (Persistent SSD)';
      }

      return number_disks + ' x ' + parseInt(storage_capacity_per_node / number_disks) + disk_string;
    },
  },
};
</script>

<style scoped>
.summary {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}
</style>
