<template>
  <div class="card">
    <slot />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Card',
};
</script>

<style scoped>
.card {
  position: relative;
  padding: 30px 20px;
  border: 1px solid var(--border);
  border-radius: var(--border-radius);
  cursor: pointer;
  background: #fff;
}
h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0;
  font-weight: 600;
}
ul,
p {
  margin-top: 1em;
}
.card[disabled] {
  filter: grayscale(100%);
  opacity: 50%;
  cursor: default;
}
.card:not([disabled]):hover {
  border-color: var(--blue);
}
.card.active,
.card.card-active {
  background-color: var(--blue-faded);
  border-color: var(--blue);
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); */
}
.card.active:after,
.card.card-active:after {
  content: '';
  position: absolute;
  top: 10px;
  right: 10px;
  background: url(/public/static/check_circle.svg) center no-repeat;
  width: 20px;
  height: 20px;
}
</style>
