import axios from 'axios';
import jszip from 'jszip';
import yamljs from 'yamljs';

function prepare_amazon_templates(product, raw_input_data, instance_type, number, region, license, createVNet) {
  let zip = new jszip();
  let templatestrings = [];

  return new Promise((resolve, reject) => {
    axios
      .get(`https://s3.eu-central-1.amazonaws.com/cloudtools.exasol.com/amazon/${product}_amazon_custom.json`, {
        headers: { 'Access-Control-Allow-Origin': '*' },
      })
      .then((response) => {
        templatestrings.push({
          name: `${product}_amazon.json`,
          content: response.data,
        });

        return axios
          .get(
            `https://s3.eu-central-1.amazonaws.com/cloudtools.exasol.com/amazon/${product}_amazon_custom_generate_new_VNET.json`,
            { headers: { 'Access-Control-Allow-Origin': '*' } }
          )
          .then((response) => {
            templatestrings.push({
              name: `${product}_amazon_generate_new_VNET.json`,
              content: response.data,
            });

            let filecontent = '';
            templatestrings.forEach((x) => {
              let region_key = x.content.Metadata.Images.regions[region.key].RegionKey;
              Object.keys(x.content.Metadata.Images[region_key]).forEach((y) => {
                if (y.includes(license)) {
                  x.content.Parameters.ImageId.Default = x.content.Metadata.Images[region_key][y]['AMI'];
                }
              });
              x.content.Parameters.DatabaseNodeInstanceType.Default =
                x.content.Parameters.DatabaseNodeInstanceType.AllowedValues.filter((x) => x.includes(instance_type))[0];

              if (product == 'exasol_cluster') {
                let storage_capacity_per_node = parseInt((raw_input_data * 1.2 * 1000) / number);
                x.content.Parameters.DBNodeCount.Default = number;
                x.content.Parameters.BlockDeviceCount.Default = Math.max(
                  Math.min(parseInt(storage_capacity_per_node / 334), 10),
                  1
                );
                x.content.Parameters.BlockDeviceVolumeSize.Default = parseInt(
                  storage_capacity_per_node / x.content.Parameters.BlockDeviceCount.Default
                );
              } else {
                let storage_capacity_per_node = parseInt(raw_input_data * 0.7 * 1000);
                x.content.Parameters.BlockDeviceCount.Default = Math.max(
                  Math.min(parseInt(storage_capacity_per_node / 334), 10),
                  1
                );
                x.content.Parameters.BlockDeviceVolumeSize.Default = parseInt(
                  storage_capacity_per_node / x.content.Parameters.BlockDeviceCount.Default
                );
              }

              zip.file(x.name, JSON.stringify(x.content));
              if (x.name.includes('VNET') && createVNet) {
                filecontent = JSON.stringify(x.content);
              } else if (!x.name.includes('VNET') && !createVNet) {
                filecontent = JSON.stringify(x.content);
              }
            });
            const randomString = Math.random().toString(34).substring(2);
            const options = {
              headers: {
                'Content-Type': 'multipart/form-data',
                'X-Api-Key': 'HUIUOslOh07rIhSGCYsCe7SuatevzUfO66hGh4Y8',
              },
            };

            return axios
              .get(
                `https://vd6gkodo8a.execute-api.eu-central-1.amazonaws.com/cloudui-generate-signed-url?method=putObject&keyname=${randomString}`,
                options
              )
              .then((response) => {
                return axios
                  .put(response.data, filecontent, options)
                  .then(() => {
                    return zip.generateAsync({
                      base64: true,
                      compression: 'DEFLATE',
                      type: 'base64',
                    });
                  })
                  .then((zip_base64) => {
                    return axios
                      .get(
                        `https://vd6gkodo8a.execute-api.eu-central-1.amazonaws.com/cloudui-generate-signed-url?method=getObject&keyname=${randomString}`,
                        options
                      )
                      .then((response) => {
                        resolve({
                          templates_as_base64_zip: zip_base64,
                          deployment_url: `https://${region.key}.console.aws.amazon.com/cloudformation/home#/stacks/create/review?templateURL=${response.data}`,
                        });
                      });
                  });
              });
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function prepare_azure_templates(product, raw_input_data, instance_type, number, license, createVNet) {
  let zip = new jszip();
  let templatestrings = [];

  return new Promise((resolve, reject) => {
    return axios
      .get(
        `https://s3.eu-central-1.amazonaws.com/cloudtools.exasol.com/azure/${product}_azure_marketplace_generate_new_VNET.json`,
        { headers: { 'Access-Control-Allow-Origin': '*' } }
      )
      .then((response) => {
        templatestrings.push({
          name: `${product}_azure_generate_new_VNET.json`,
          content: response.data,
        });

        return axios
          .get(`https://s3.eu-central-1.amazonaws.com/cloudtools.exasol.com/azure/${product}_azure_marketplace.json`, {
            headers: { 'Access-Control-Allow-Origin': '*' },
          })
          .then((response) => {
            templatestrings.push({
              name: `${product}_azure.json`,
              content: response.data,
            });

            let filecontent = '';
            templatestrings.forEach((x) => {
              x.content.parameters.nodeVmSize.defaultValue = instance_type;
              delete x.content.parameters.nodeVmSize.allowedValues;

              x.content.parameters.imageType.defaultValue = x.content.parameters.imageType.allowedValues.filter((x) =>
                x['name'].includes(license)
              )[0];
              delete x.content.parameters.imageType.allowedValues;

              if (product == 'exasol_cluster') {
                let storage_capacity_per_node = parseInt((raw_input_data * 1.2 * 1000) / number);
                x.content.parameters.numExasolNodes.defaultValue = number;
                x.content.parameters.nodeNdisks.defaultValue = get_optimal_disk_size(
                  [256, 512, 1024, 2048],
                  storage_capacity_per_node
                );
                x.content.parameters.nodeDataDiskSizeGB.defaultValue = parseInt(
                  storage_capacity_per_node / x.content.parameters.nodeNdisks.defaultValue
                );
              } else {
                let storage_capacity_per_node = parseInt(raw_input_data * 0.7 * 1000);
                x.content.parameters.nodeNdisks.defaultValue = get_optimal_disk_size(
                  [256, 512, 1024, 2048],
                  storage_capacity_per_node
                );
                x.content.parameters.nodeDataDiskSizeGB.defaultValue = parseInt(
                  storage_capacity_per_node / x.content.parameters.nodeNdisks.defaultValue
                );
              }
              zip.file(x.name, JSON.stringify(x.content));
              if (x.name.includes('VNET') && createVNet) {
                filecontent = JSON.stringify(x.content);
              } else if (!x.name.includes('VNET') && !createVNet) {
                filecontent = JSON.stringify(x.content);
              }
            });
            const randomString = Math.random().toString(34).substring(2);
            const options = {
              headers: {
                'Content-Type': 'multipart/form-data',
                'X-Api-Key': 'HUIUOslOh07rIhSGCYsCe7SuatevzUfO66hGh4Y8',
              },
            };

            return axios
              .get(
                `https://vd6gkodo8a.execute-api.eu-central-1.amazonaws.com/cloudui-generate-signed-url?method=putObject&keyname=${randomString}`,
                options
              )
              .then((response) => {
                axios
                  .put(response.data, filecontent, options)
                  .then(() => {
                    return zip.generateAsync({
                      base64: true,
                      compression: 'DEFLATE',
                      type: 'base64',
                    });
                  })
                  .then((zip_base64) => {
                    axios
                      .get(
                        `https://vd6gkodo8a.execute-api.eu-central-1.amazonaws.com/cloudui-generate-signed-url?method=getObject&keyname=${randomString}`,
                        options
                      )
                      .then((response) => {
                        resolve({
                          templates_as_base64_zip: zip_base64,
                          deployment_url: `https://portal.azure.com/#create/Microsoft.Template/uri/${encodeURIComponent(
                            response.data
                          )}`,
                        });
                      });
                  });
              });
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function prepare_google_templates(product, raw_input_data, instance_type, number, region, license, createVNet) {
  let zip = new jszip();
  let cloudshellzip = new jszip();
  let templatestrings = [];
  let filename;

  return new Promise((resolve, reject) => {
    return axios
      .get(`https://s3.eu-central-1.amazonaws.com/cloudtools.exasol.com/google/${product}_google.jinja`, {
        headers: { 'Access-Control-Allow-Origin': '*' },
      })
      .then((response) => {
        templatestrings.push({
          name: `${product}_google.jinja`,
          content: response.data,
        });

        return axios
          .get(`https://s3.eu-central-1.amazonaws.com/cloudtools.exasol.com/google/${product}_google.yaml`, {
            headers: { 'Access-Control-Allow-Origin': '*' },
          })
          .then((response) => {
            templatestrings.push({
              name: `${product}_google.yaml`,
              content: response.data,
            });

            return axios
              .get(
                `https://s3.eu-central-1.amazonaws.com/cloudtools.exasol.com/google/${product}_google_generate_new_VNET.jinja`,
                { headers: { 'Access-Control-Allow-Origin': '*' } }
              )
              .then((response) => {
                templatestrings.push({
                  name: `${product}_google_generate_new_VNET.jinja`,
                  content: response.data,
                });

                return axios
                  .get(
                    `https://s3.eu-central-1.amazonaws.com/cloudtools.exasol.com/google/${product}_google_generate_new_VNET.yaml`,
                    { headers: { 'Access-Control-Allow-Origin': '*' } }
                  )
                  .then((response) => {
                    templatestrings.push({
                      name: `${product}_google_generate_new_VNET.yaml`,
                      content: response.data,
                    });

                    templatestrings.forEach((y) => {
                      if (y.name.includes('yaml')) {
                        let jsonObject = yamljs.parse(y.content);

                        jsonObject.resources[0].properties.machineTypeDatabaseNode = instance_type;
                        jsonObject.resources[0].properties.zone = region.key + '-b';
                        jsonObject.resources[0].properties.image =
                          license == 'BYOL'
                            ? 'https://www.googleapis.com/compute/v1/projects/public-marketplace/global/images/exasol-7-1-25-byol'
                            : 'https://www.googleapis.com/compute/v1/projects/public-marketplace/global/images/exasol-7-1-25-payg';

                        if (product == 'exasol_cluster') {
                          let storage_capacity_per_node = parseInt((raw_input_data * 1.2 * 1000) / number);
                          jsonObject.resources[0].properties.dbNodeCount = number;
                          jsonObject.resources[0].properties.blockDeviceCount = get_optimal_disk_size(
                            [256, 512, 1000, 1500, 2048],
                            storage_capacity_per_node
                          );
                          jsonObject.resources[0].properties.blockDeviceVolumeSizeGB = parseInt(
                            storage_capacity_per_node / jsonObject.resources[0].properties.blockDeviceCount
                          );
                        } else {
                          let storage_capacity_per_node = parseInt(raw_input_data * 0.7 * 1000);
                          jsonObject.resources[0].properties.blockDeviceCount = get_optimal_disk_size(
                            [256, 512, 1000, 1500, 2048],
                            storage_capacity_per_node
                          );
                          jsonObject.resources[0].properties.blockDeviceVolumeSizeGB = parseInt(
                            storage_capacity_per_node / jsonObject.resources[0].properties.blockDeviceCount
                          );
                        }

                        zip.file(y.name, yamljs.stringify(jsonObject, 4));
                        if (y.name.includes('VNET') && createVNet) {
                          cloudshellzip.file(y.name, yamljs.stringify(jsonObject, 4));
                          filename = y.name;
                        } else if (!y.name.includes('VNET') && !createVNet) {
                          cloudshellzip.file(y.name, yamljs.stringify(jsonObject, 4));
                          filename = y.name;
                        }
                      } else {
                        zip.file(y.name, y.content);
                        if (y.name.includes('VNET') && createVNet) {
                          cloudshellzip.file(y.name, y.content);
                        } else if (!y.name.includes('VNET') && !createVNet) {
                          cloudshellzip.file(y.name, y.content);
                        }
                      }
                    });

                    const randomString = Math.random().toString(34).substring(2);
                    const options = {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        'X-Api-Key': 'HUIUOslOh07rIhSGCYsCe7SuatevzUfO66hGh4Y8',
                      },
                    };
                    return Promise.all([
                      axios.get(
                        `https://vd6gkodo8a.execute-api.eu-central-1.amazonaws.com/cloudui-generate-signed-url?method=putObject&keyname=${randomString}`,
                        options
                      ),
                      cloudshellzip.generateAsync({
                        base64: true,
                        compression: 'DEFLATE',
                        type: 'base64',
                      }),
                    ]).then(([response, zip_base64]) => {
                      return axios
                        .put(response.data, zip_base64, options)
                        .then(() => {
                          return zip.generateAsync({
                            base64: true,
                            compression: 'DEFLATE',
                            type: 'base64',
                          });
                        })
                        .then((zip_base64) => {
                          axios
                            .get(
                              `https://vd6gkodo8a.execute-api.eu-central-1.amazonaws.com/cloudui-generate-signed-url?method=getObject&keyname=${randomString}`,
                              options
                            )
                            .then((response) => {
                              resolve({
                                templates_as_base64_zip: zip_base64,
                                deployment_url: `https://console.cloud.google.com/cloudshell/open?git_repo=https%3A%2F%2Fgithub.com%2F${btoa(
                                  response.data
                                ).replace(
                                  /=/g,
                                  '_'
                                )}&cloudshell_image=gcr.io/development-marketplace/exasol_cloud_wizard:latest&cloudshell_print=${filename}`,
                              });
                            });
                        });
                    });
                  });
              });
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function get_optimal_disk_size(preconfigured_disk_sizes, storage_capacity_per_node) {
  preconfigured_disk_sizes.sort((a, b) => {
    if (storage_capacity_per_node % a < storage_capacity_per_node % b) {
      return -1;
    } else if (storage_capacity_per_node % a > storage_capacity_per_node % b) {
      return 1;
    } else {
      return 0;
    }
  });
  return Math.max(Math.min(parseInt(storage_capacity_per_node / preconfigured_disk_sizes[0]), 10), 1);
}

export default {
  prepare_azure_templates,
  prepare_google_templates,
  prepare_amazon_templates,
  get_optimal_disk_size,
};
