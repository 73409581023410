<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="row-container">
    <v-select
      v-model="cluster_model.instance_type"
      :items="cluster_model.instance_types"
      label="Instance Model"
      solo
      @change="changeType"
    />
  </div>
</template>

<script>
export default {
  name: 'InstanceModel',
  props: ['change_instance_family', 'cluster_model', 'instance_type_changed', 'raw_data_to_resource_alignment'],
  data() {
    return {
      instanceTypes: this.cluster_model.instance_types.map((it) => ({
        name: it || '', // .replace('Standard_', '')
        value: it,
      })),
    };
  },
  // computed: {
  //   instanceTypes() {
  //     return this.cluster_model.instance_types.map((it) => ({
  //       name: (it || '').replace('Standard_', ''),
  //       value: it
  //     }));
  //   }
  // },
  methods: {
    changeType(n) {
      // eslint-disable-next-line vue/no-mutating-props
      this.cluster_model.instance_type = n;
      this.instance_type_changed(this.cluster_model, true);
      this.raw_data_to_resource_alignment(this.cluster_model);
    },
  },
};
</script>

<style scoped>
.row-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.row-container .card {
  width: 100%;
  margin: 0px auto 10px;
}
</style>
