// import { uuid } from 'uuidv4';

export const initSegment = (apiKey, callback) => {
  !(function () {
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize) {
      if (analytics.invoked) {
        // eslint-disable-next-line no-console
        window.console && console.error && console.error('Segment snippet included twice.');
      } else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware',
        ];
        analytics.factory = function (t) {
          return function () {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (var t = 0; t < analytics.methods.length; t++) {
          var e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t, e) {
          var n = document.createElement('script');
          n.type = 'text/javascript';
          n.async = !0;
          n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
          var a = document.getElementsByTagName('script')[0];
          a.parentNode.insertBefore(n, a);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = '4.1.0';
        analytics.load(apiKey);
        analytics.page();
        if (callback) callback();
      }
    }
  })();
};

export const init = (callback) => {
  initSegment('H6VLUlIcAe0SdlRBLcPlA2pJeaaJuUhN', callback);
};

export const event = (name, data) => {
  if (window.analytics && name) {
    window.analytics.track(name, data);
  }
};

export const page = ({ path, title }) => {
  if (window.analytics && path) {
    window.analytics.page({
      path,
      referrer: window.referrer,
      search: window.location.search,
      title,
      url: window.location.href,
    });
  }
};

export const identify = (data, id) => {
  // const storedId = window.localStorage.getItem('_exa_id');
  // id = id || storedId;
  // if (!id) {
  //   id = uuid();
  //   window.localStorage.setItem('_exa_id', id);
  // }

  if (window.analytics) {
    window.analytics.identify(id, data);
  }
};
