<template>
  <div class="col2">
    <Card hover :class="cluster_model.license == 'PAYG' ? 'active' : ''" @click.native="changeLicense('PAYG')">
      <h4>Pay-as-you-go</h4>
      <div v-show="helper_sections.license">
        <ul>
          <li>Simply pay for what you use</li>
          <li>Charged on an hourly basis</li>
          <li>Billed through the cloud Marketplace</li>
          <li>Includes 24x7 enterprise support</li>
        </ul>
      </div>
    </Card>

    <Card hover :class="cluster_model.license == 'BYOL' ? 'active' : ''" @click.native="changeLicense('BYOL')">
      <h4>Bring-your-own-license</h4>
      <p v-show="helper_sections.license">
        Select this option if you already have a valid Exasol license for the selected configuration or if you plan to
        purchase a license.
        <a href="mailto:sales@exasol.com">[Contact Sales]</a>
      </p>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/common/Card';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'License',
  components: {
    Card,
  },
  props: ['helper_sections', 'cluster_model', 'setClusterModel'],
  methods: {
    changeLicense(license) {
      const cm = Object.assign({}, this.cluster_model, { license });
      this.setClusterModel(cm);
    },
  },
};
</script>
