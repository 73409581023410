<template>
  <div :class="`section-container${full ? 'full' : ''}`">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SectionContainer',
  props: ['full'],
};
</script>

<style scoped>
.section-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.section-container.full {
  max-width: 100%;
}
</style>
