<template>
  <SummaryFrame :full="true" :cluster_model="cluster_model">
    <div class="finish-wrapper">
      <div class="launch-title">
        <h2 v-if="cluster_model.cloudplatform !== 'google'">Launch your configuration</h2>
        <h2 v-if="cluster_model.cloudplatform === 'google'">Download your configuration</h2>
        <a
          href="https://docs.exasol.com/administration/aws/installation/cloud_wizard.htm"
          target="_blank"
          rel="noopener"
        >
          Learn More
        </a>
      </div>

      <div class="deploy-options">
        <v-radio-group v-model="launcherContent.generate_new_VPC_selected" :mandatory="true">
          <v-radio v-for="n in launchOptions" :key="n.label" color="primary" :label="n.label" :value="n.value" />
        </v-radio-group>

        <button
          v-if="cluster_model.cloudplatform === 'google'"
          :class="`deploy-btn primary ${platformDetails.btnClass}`"
          @click="launchStack(true)"
        >
          {{ preparing && !downloading ? 'Preparing template...' : `Download template` }}
        </button>

        <button
          v-if="cluster_model.cloudplatform !== 'google'"
          :class="`deploy-btn primary ${platformDetails.btnClass}`"
          @click="launchStack(false)"
        >
          {{ preparing && !downloading ? 'Preparing template...' : `Launch on ${platformDetails.btnName}` }}
        </button>

        <div class="orbar" />

        <p v-if="cluster_model.cloudplatform !== 'google'" style="margin-top: 20px">
          Or,
          <strong>
            <a @click="launchStack(true)"> download the configuration templates </a>
          </strong>
          to deploy yourself
        </p>
      </div>

      <!-- Hidden Button to Help Download Template -->
      <a
        id="download_templates"
        :style="{ visibility: 'hidden' }"
        :href="'data:application/octet-stream;charset=utf-8;base64,' + launcherContent.templates_as_base64_zip"
        download="exasol-templates.zip"
      />
    </div>

    <v-dialog v-model="showModal" width="500" class="launch-modal">
      <v-card>
        <v-progress-linear v-if="preparing" :indeterminate="true" class="launch-progress"></v-progress-linear>

        <div class="top-bar">
          <h3 v-if="cluster_model.cloudplatform !== 'google'">Launch on {{ platformDetails.btnName }}</h3>
          <h3 v-if="cluster_model.cloudplatform === 'google'">Template for {{ platformDetails.btnName }}</h3>
        </div>

        <div v-if="preparing" class="launch-body">
          <h3>Preparing template...</h3>
        </div>

        <div v-if="!preparing" class="launch-body">
          <div v-if="cluster_model.cloudplatform === 'amazon'" class="">
            <AlertBox theme="warning" class="warning-alert">
              <div class="warning-box">
                <img class="icon" src="/static/warning.svg" width="23" height="30" />
                <h4>Action Required</h4>
              </div>
            </AlertBox>

            <p style="margin-bottom: 30px">
              Before clicking the deploy button below, you must first click the
              <b>"Continue to Subscribe"</b> button on the
              <a :href="amazonMarketplaceLink" target="_blank" rel="noopener"> Amazon Marketplace Page</a>.
            </p>
          </div>

          <div v-if="cluster_model.cloudplatform !== 'amazon'" class="">
            <h3 style="margin-bottom: 30px">Template is ready!</h3>
          </div>

          <button
            v-if="!downloading && !preparing"
            :class="`deploy-btn primary ${platformDetails.btnClass}`"
            @click="launchTemplate"
          >
            Deploy Template on {{ platformDetails.btnName }}
          </button>
        </div>
      </v-card>
    </v-dialog>
  </SummaryFrame>
</template>

<script>
import template_service from '@/service/template-service.js';
import SummaryFrame from '../SummaryFrame';
import Card from '@/components/common/Card.vue';
import AlertBox from '@/components/common/AlertBox';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Finish',
  components: {
    SummaryFrame,
    // eslint-disable-next-line vue/no-unused-components
    Card,
    AlertBox,
  },
  props: ['cluster_model'],
  data() {
    return {
      preparing: false,
      downloading: false,
      showModal: false,
      launcherContent: {
        generate_new_VPC_selected: true,
        templates_as_base64_zip: null,
        deployment_url: null,
      },
    };
  },
  computed: {
    amazonMarketplaceLink() {
      return `https://aws.amazon.com/marketplace/pp/prodview-${
        this.cluster_model.license === 'PAYG' ? 'lkdvwqobetjzu' : 'sxxgpdur7udao'
      }`;
    },
    modalTitle() {
      if (this.preparing) return 'Preparing Template...';

      const platform = this.cluster_model.cloudplatform;
      if (platform === 'amazon') {
        // https://aws.amazon.com/marketplace/pp/prodview-sxxgpdur7udao
        return 'Before you click the button below to launch your template, go to';
      }

      return 'Template Ready';
    },

    launchOptions() {
      const details = this.platformDetails;
      return [
        {
          label: `Create new ${details.vpcType}`,
          value: true,
        },
        {
          label: `Use existing ${details.vpcType}`,
          value: false,
        },
      ];
    },
    platformDetails() {
      const platform = this.cluster_model.cloudplatform;
      let btnName,
        btnClass = '',
        vpcType = 'VPC';
      if (platform === 'amazon') {
        btnName = 'AWS';
      }
      if (platform === 'google') {
        btnName = 'GCP';
      }
      if (platform === 'azure') {
        btnName = 'Azure';
        vpcType = 'VNet';
      }
      btnClass = btnName.toLowerCase();
      return {
        btnName,
        btnClass,
        vpcType,
      };
    },
  },
  methods: {
    launchStack: async function (download) {
      this.showModal = true;
      this.preparing = true;
      this.downloading = download;
      const platform = this.cluster_model.cloudplatform;
      if (platform === 'amazon') {
        await this.prepare_amazon_templates(download);
      }
      if (platform === 'google') {
        await this.prepare_google_templates(download);
      }
      if (platform === 'azure') {
        await this.prepare_azure_templates(download);
      }
      this.preparing = false;
      this.runActions();
      return;
    },
    runActions() {
      if (this.downloading) {
        document.getElementById('download_templates').click();
        this.showModal = false;
      }
    },
    launchTemplate() {
      if (!this.launcherContent.deployment_url) return;
      window.open(this.launcherContent.deployment_url, '_blank');
    },
    prepare_amazon_templates: async function () {
      const res = await template_service.prepare_amazon_templates(
        this.cluster_model.product,
        this.cluster_model.budget == 'Custom' ? this.cluster_model.raw_data : this.cluster_model.size,
        this.cluster_model.instance_overview.name,
        this.cluster_model.instance_overview.number,
        this.cluster_model.region,
        this.cluster_model.license,
        this.launcherContent.generate_new_VPC_selected
      );

      this.launcherContent.templates_as_base64_zip = res.templates_as_base64_zip;
      this.launcherContent.deployment_url = res.deployment_url;

      return;
    },
    prepare_azure_templates: async function () {
      const res = await template_service.prepare_azure_templates(
        this.cluster_model.product,
        this.cluster_model.budget == 'Custom' ? this.cluster_model.raw_data : this.cluster_model.size,
        this.cluster_model.instance_overview.name,
        this.cluster_model.instance_overview.number,
        this.cluster_model.license,
        this.launcherContent.generate_new_VPC_selected
      );

      this.launcherContent.templates_as_base64_zip = res.templates_as_base64_zip;
      this.launcherContent.deployment_url = res.deployment_url;

      return;
    },

    prepare_google_templates: async function () {
      const res = await template_service.prepare_google_templates(
        this.cluster_model.product,
        this.cluster_model.budget == 'Custom' ? this.cluster_model.raw_data : this.cluster_model.size,
        this.cluster_model.instance_overview.name,
        this.cluster_model.instance_overview.number,
        this.cluster_model.region,
        this.cluster_model.license,
        this.launcherContent.generate_new_VPC_selected
      );

      this.launcherContent.templates_as_base64_zip = res.templates_as_base64_zip;
      this.launcherContent.deployment_url = res.deployment_url;
      return;
    },
  },
};
</script>

<style scoped>
.deploy-btn {
  width: 100%;
  height: 44px;
  background: linear-gradient(180deg, #f6dc9e 0%, #f0c455 100%);
  box-shadow: inset 0px 2px 0px #faecc7;
  border-radius: 4px;
  font-weight: 700;
  border: 1px solid #a88734 !important;
}
.deploy-btn:hover {
  background: linear-gradient(180deg, #e0c37e 0%, #d3a737 100%);
  border: 1px solid #a88734 !important;
}

.deploy-btn:not(.aws) {
  background: var(--blue-cloud);
  box-shadow: none !important;
  border: none !important;
  color: #fff;
}
.deploy-btn:not(.aws):hover {
  background: var(--blue-cloud-hover) !important;
  border: none !important;
}

.finish-wrapper {
  margin-bottom: 60px;
}

.launch-title {
  display: flex;
  align-items: center;
}

.launch-title h2 {
  margin: 0 10px 0 0;
}

.launch-title a {
  font-weight: 700;
}

.launch-progress {
  margin: 0;
}

.top-bar {
  padding: 30px;
  background: var(--gray-bg) !important;
  text-align: left;
}

.launch-modal {
  text-align: left;
}

.top-bar h3 {
  font-size: 140%;
  font-weight: 400;
  margin: 0;
}

.launch-body {
  padding: 15px 30px 30px;
  text-align: left;
}

.warning-box {
  display: flex;
  align-items: center;
}

.warning-alert {
  padding: 20px;
  margin-bottom: 20px;
}

.warning-box h4 {
  margin: 0;
}
.warning-box .icon {
  margin-right: 8px;
}

.v-dialog .v-sheet {
  border-radius: 10px !important;
}
</style>
