export const clusterSizes = [
  { name: '1 TB', value: 1, product: 'exasol_single_node' },
  { name: '2 TB', value: 2, product: 'exasol_cluster' },
  { name: '5 TB', value: 5, product: 'exasol_cluster', selected: true },
  { name: '10 TB', value: 10, product: 'exasol_cluster' },
  { name: '25 TB', value: 25, product: 'exasol_cluster' },
  { name: '50 TB', value: 50, product: 'exasol_cluster' },
  { name: '75 TB', value: 75, product: 'exasol_cluster' },
  { name: '100 TB', value: 100, product: 'exasol_cluster' },
  { name: '150 TB', value: 150, product: 'exasol_cluster' },
  { name: '200 TB', value: 200, product: 'exasol_cluster' },
  { name: '300 TB', value: 300, product: 'exasol_cluster' },
  { name: '400 TB', value: 400, product: 'exasol_cluster' },
];

export const defaultClusterModel = {
  cloudplatform: null,
  region: null,
  regions: [],
  size: clusterSizes.find((it) => it && it.selected).value,
  product: 'exasol_cluster',
  budget: 'Balanced',
  license: 'PAYG',
  instance_families: [],
  instance_family: null,
  instance_groups: [],
  instance_group: null,
  instance_types: [],
  instance_type: null,
  instance_type_mgmt: null,
  raw_data: null,
  num_node: 2,
  instance_overview: {
    num_node: 0,
    vCPU: 0,
    Memory: 0,
    Cost: 0,
    mgmtCost: 0,
    name: '',
    number: 2,
    licenseCostAll: 0,
    infrastructureCostAll: 0,
  },
};

export const docSources = {
  aws: {
    url: 'https://docs.exasol.com/cloud_platforms/aws.htm',
    video: 'https://www.youtube.com/watch?v=ht4Opvgs5qc',
  },
  gcp: {
    url: 'https://docs.exasol.com/cloud_platforms/google_cloud.htm',
    video: 'https://www.youtube.com/watch?v=PHBj6Qaq1l0',
  },
  azure: {
    url: 'https://docs.exasol.com/cloud_platforms/microsoft_azure.htm',
    video: 'https://www.youtube.com/watch?v=c_aJCxYKTCY',
  },
};

export const getDocSource = (type) => docSources[type];

export const budgetTypes = ['High Performance', 'Balanced', 'Basic'];
