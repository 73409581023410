<template>
  <div class="toggler">
    <button :class="advanced_view ? '' : 'active'" @click="toggleConfig(false)">
      <span>Basic Configuration</span>
    </button>
    <button :class="advanced_view ? 'active' : ''" @click="toggleConfig(true)">
      <span>Advanced Configuration</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ConfigToggle',
  props: ['switch_to_advanced_configurator', 'switch_to_basic_configurator', 'advanced_view', 'instance_type_changed'],
  methods: {
    toggleConfig(advanced) {
      if (advanced) {
        this.switch_to_advanced_configurator();
      } else {
        this.switch_to_basic_configurator();
      }
    },
  },
};
</script>

<style scoped>
.toggler {
  display: inline-block;
  border: 1px solid var(--blue);
  border-radius: var(--border-radius);
  font-weight: 600;
}

button {
  color: var(--blue);
  padding: 2px 15px;
}

button.active {
  color: #fff;
  background: var(--blue);
}
</style>
