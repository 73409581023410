<template>
  <div class="wizard">
    <div class="top">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1"> Cloud Platform </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2"> Configuration </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 3" step="3">Summary</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <!-- CLOUD PLATFORM -->
          <v-stepper-content step="1">
            <Platform
              :platform_path="platform_path"
              :cluster_model="cluster_model"
              :cloud-type="cloudType"
              @setClusterModel="setClusterModel"
            />
          </v-stepper-content>

          <!-- CONFIGURATION -->
          <v-stepper-content step="2">
            <ConfigToggle
              :advanced_view="advanced_view"
              :switch_to_advanced_configurator="switch_to_advanced_configurator"
              :switch_to_basic_configurator="switch_to_basic_configurator"
              :instance_type_changed="instance_type_changed"
            />
            <Config
              :cluster_model="cluster_model"
              :cluster_model_advanced="cluster_model_advanced"
              :advanced_view="advanced_view"
              :config-is-available="configIsAvailable"
              :set-cluster-model="setClusterModel"
              :platform_path="platform_path"
              :performance_level="performance_level"
              :switch_to_advanced_configurator="switch_to_advanced_configurator"
              :instance_type_changed="instance_type_changed"
              :helper_sections="helper_sections"
              :change_instance_family="change_instance_family"
              :description_instance_families="description_instance_families"
              :change_group="change_group"
              :raw-data-exceeded="rawDataExceeded"
            />
          </v-stepper-content>

          <!-- SUMMARY -->
          <v-stepper-content
            v-if="!advanced_view && cluster_model.cloudplatform != null && cluster_model.product != null"
            step="3"
          >
            <Finish :cluster_model="cluster_model" />
          </v-stepper-content>
          <v-stepper-content
            v-if="
              advanced_view && cluster_model_advanced.cloudplatform != null && cluster_model_advanced.product != null
            "
            step="3"
          >
            <Finish :cluster_model="cluster_model_advanced" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>

    <button-row
      :step="step"
      :cluster_model="advanced_view ? cluster_model_advanced : cluster_model"
      @setStep="setStep"
    />
  </div>
</template>

<script>
import resource_metadata_service from '@/service/resource-metadata-service';
import sizing_wizard_input from '@/assets/sizing_wizard_input.json';
import sizing_selection from '@/assets/sizing_selection.json';
import license_cost from '@/assets/license_cost_ram.json';
import Summary from '@/components/common/Summary';
import Platform from './sections/Platform';
import Finish from './sections/Finish';
import ButtonRow from './ButtonRow';
import AlertBox from '@/components/common/AlertBox';
import { defaultClusterModel, budgetTypes, clusterSizes } from './constants';
import * as track from '../../service/tracking';
import ConfigToggle from '@/components/common/ConfigToggle';
import Config from './sections/Config';

export default {
  name: 'SizingWizard',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names, vue/no-unused-components
    Summary,
    Platform,
    Config,
    Finish,
    // eslint-disable-next-line vue/no-unused-components
    AlertBox,
    ButtonRow,
    ConfigToggle,
  },
  props: ['cloudType'],
  data() {
    return {
      step: 1,
      showDevData: false,
      cluster_model_advanced: Object.assign(JSON.parse(JSON.stringify(defaultClusterModel)), { budget: 'Custom' }),
      cluster_model: JSON.parse(JSON.stringify(defaultClusterModel)),
      advanced_view: false,
      emailRules: [
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'E-mail must be valid',
      ],
      rawDataExceeded: true,
      description_instance_families: [
        'This family provides a balance of compute, memory and network resources',
        'Optimized for compute-intense workloads',
        'Optimized for memory-intensive applications and offer better price per GiB',
      ],
      model_slider: null,
      num_slider: null,
      size_slider: null,
      helper_sections: { license: true, product: true, family: true },
      performance_level: {
        basic: false,
        balanced: false,
        high_performance: false,
      },
      platform_path: null,
    };
  },
  mounted() {
    this.platform_path = this.$router.currentRoute.path;
    this.setStep(this.step);
  },
  methods: {
    setStep(step) {
      if (this.step === 2 && step === 1) {
        this.switch_to_basic_configurator();
      }
      if (this.step !== 3 && step !== 3) {
        this.cluster_model_advanced = Object.assign(JSON.parse(JSON.stringify(this.cluster_model)), {
          budget: 'Custom',
        });
      }

      this.step = step;
      const title = 'Sizing Wizard';
      const path = `/step-${step}`;
      TrackEvent;
      history.pushState(null, title, path);
      track.page({ path, title });
    },
    setModel(cluster_model) {
      if (cluster_model.budget === 'Custom') {
        this.cluster_model_advanced = cluster_model;
      } else {
        this.cluster_model = cluster_model;
      }
    },
    setClusterModel(data, callback) {
      const size = Number(data.size);
      const budget = data.budget;
      const clusterOption = clusterSizes.find((it) => it.value === size);
      data.size = Number(size);
      data.product = clusterOption.product;
      this.setModel(data);
      data.raw_data = Number(
        data.product == 'exasol_single_node'
          ? ((data.instance_overview.memory * 10 * 0.7) / 1000).toFixed(3)
          : ((data.instance_overview.memory * data.num_node * 10 * 0.9) / 1000).toFixed(3)
      );

      this.instance_type_changed(data, budget === 'Custom');
      this.$emit('setCloudType', data.cloudplatform);

      if (callback) callback(data);
    },

    change_instance_family(cluster_model, instance_family) {
      cluster_model.instance_family = instance_family;
      cluster_model.instance_groups = [];
      cluster_model.instance_types = [];
      if (cluster_model.cloudplatform == 'amazon') {
        sizing_selection[cluster_model.license][cluster_model.cloudplatform]['instances'][instance_family]
          .map((x) => x.split('.')[0])
          .forEach((x) => {
            if (
              !cluster_model.instance_groups.includes(x) &&
              this.getInstanceTypes(cluster_model, instance_family, x).length > 0
            ) {
              cluster_model.instance_groups.push(x);
            }
          });
      } else if (cluster_model.cloudplatform == 'azure') {
        sizing_selection[cluster_model.license][cluster_model.cloudplatform]['instances'][instance_family]
          .map((x) => x.match(/Standard_[A-Z]+/)[0])
          .forEach((x) => {
            if (
              !cluster_model.instance_groups.includes(x) &&
              this.getInstanceTypes(cluster_model, instance_family, x).length > 0
            )
              cluster_model.instance_groups.push(x);
          });
      } else if (cluster_model.cloudplatform == 'google') {
        sizing_selection[cluster_model.license][cluster_model.cloudplatform]['instances'][instance_family]
          .map((x) => x.match(/[m-n][1-2]-[a-z]+/)[0])
          .forEach((x) => {
            if (
              !cluster_model.instance_groups.includes(x) &&
              this.getInstanceTypes(cluster_model, instance_family, x).length > 0
            )
              cluster_model.instance_groups.push(x);
          });
      }
      if (cluster_model.instance_groups.length) {
        this.change_group(cluster_model, cluster_model.instance_groups[0]);
      }
    },

    change_group(cluster_model, instance_group) {
      cluster_model.instance_group = instance_group;
      cluster_model.instance_types = this.getInstanceTypes(
        cluster_model,
        cluster_model.instance_family,
        instance_group
      );
      // Set to first instance_types if group changed
      if (
        cluster_model.instance_types.length &&
        cluster_model.instance_group !== (cluster_model.instance_type || '').split('.')[0]
      ) {
        const type = cluster_model.instance_types[0];
        cluster_model.instance_type = type;
        this.instance_type_changed(cluster_model, true);
      }
      this.setModel(cluster_model);
    },

    getInstanceTypes(cluster_model, instance_family, instance_group) {
      const types = [];
      if (cluster_model.cloudplatform == 'amazon') {
        sizing_selection[cluster_model.license][cluster_model.cloudplatform]['instances'][instance_family].forEach(
          (x) => {
            if (
              x.includes(`${instance_group}.`) &&
              resource_metadata_service.hasInstanceModel(cluster_model.cloudplatform, cluster_model.region.key, x)
            ) {
              types.push(x);
            }
          }
        );
      } else if (cluster_model.cloudplatform == 'azure') {
        sizing_selection[cluster_model.license][cluster_model.cloudplatform]['instances'][instance_family].forEach(
          (x) => {
            if (
              x.includes(instance_group) &&
              resource_metadata_service.hasInstanceModel(cluster_model.cloudplatform, cluster_model.region.key, x)
            ) {
              types.push(x);
            }
          }
        );
      } else if (cluster_model.cloudplatform == 'google') {
        sizing_selection[cluster_model.license][cluster_model.cloudplatform]['instances'][instance_family].forEach(
          (x) => {
            if (
              x.includes(instance_group) &&
              resource_metadata_service.hasInstanceModel(cluster_model.cloudplatform, cluster_model.region.key, x)
            ) {
              types.push(x);
            }
          }
        );
      }
      return types;
    },

    setBudgetData(cluster_model, budget, size) {
      cluster_model.size = size || cluster_model.size;
      cluster_model.budget = budget || cluster_model.budget;
      const config = (sizing_wizard_input[cluster_model.license][cluster_model.product][cluster_model.size] || {})[
        budget
      ];

      if (config) {
        cluster_model.instance_overview.name = config[cluster_model.cloudplatform]['name'];

        cluster_model.instance_overview.number = config[cluster_model.cloudplatform]['number'];
      }
      this.setModel(cluster_model);
    },

    // TODO: mobe this to basic unless needed for advanced
    configIsAvailable(cluster_model, budget, size) {
      size = size || cluster_model.size;
      const opts = sizing_wizard_input[cluster_model.license][cluster_model.product];
      const platform = cluster_model.cloudplatform;
      if (!(opts[size] && opts[size][budget] && opts[size][budget][platform])) {
        return false;
      }

      return opts[size][budget][platform]['number'] != 0;
    },

    instance_type_changed(cluster_model, advanced_view) {
      try {
        this.advanced_view = advanced_view;
        if (advanced_view) {
          cluster_model.instance_overview.name = cluster_model.instance_type;
          cluster_model.instance_overview.number = cluster_model.num_node;
        } else {
          this.performance_level = this.performance_level || {};

          if (!sizing_wizard_input[cluster_model.license][cluster_model.product]) {
            return;
          }

          this.performance_level.basic =
            sizing_wizard_input[cluster_model.license][cluster_model.product][cluster_model.size]['Basic'][
              cluster_model.cloudplatform
            ]['name'] != '';

          this.performance_level.balanced =
            sizing_wizard_input[cluster_model.license][cluster_model.product][cluster_model.size]['Balanced'][
              cluster_model.cloudplatform
            ]['name'] != '';
          this.performance_level.high_performance =
            sizing_wizard_input[cluster_model.license][cluster_model.product][cluster_model.size]['High Performance'][
              cluster_model.cloudplatform
            ]['name'] != '';

          this.setBudgetData(cluster_model, cluster_model.budget);

          // Bump back the system reccomendation if it's not available
          if (!cluster_model.instance_overview.number) {
            let checkingBudget = true;
            let budgetIdx = budgetTypes.findIndex((it) => it === cluster_model.budget);
            let sizeIdx = clusterSizes.map((it) => it.value).findIndex((it) => it === cluster_model.size);
            while (!cluster_model.instance_overview.number && budgetTypes[budgetIdx] && clusterSizes[sizeIdx]) {
              if (checkingBudget) {
                budgetIdx++;
                this.setBudgetData(cluster_model, budgetTypes[budgetIdx]);
              } else {
                sizeIdx--;
                this.setBudgetData(cluster_model, undefined, clusterSizes[sizeIdx].value);
              }

              checkingBudget = !checkingBudget;
            }
          }
        }

        let resource_metadata = resource_metadata_service.get_vm_metadata(
          cluster_model.cloudplatform,
          cluster_model.region.key,
          cluster_model.instance_overview.name
        );

        cluster_model.instance_overview.cpu = parseInt(resource_metadata['cpu']);
        cluster_model.instance_overview.memory = parseInt(resource_metadata['memory']);
        cluster_model.instance_overview.price = parseFloat(resource_metadata['price']);
        cluster_model.instance_overview.license = license_cost.filter(
          (x) => x.ram >= cluster_model.instance_overview.memory
        )[0]['price'];
        if (cluster_model.product == 'exasol_cluster') {
          resource_metadata = resource_metadata_service.get_vm_metadata(
            cluster_model.cloudplatform,
            cluster_model.region.key,
            cluster_model.instance_type_mgmt
          );

          cluster_model.instance_overview.price_mgmt = parseFloat(resource_metadata['price']);
          cluster_model.instance_overview.license_mgmt = license_cost.filter(
            (x) => x.ram >= resource_metadata['memory']
          )[0]['price'];
        } else {
          cluster_model.instance_overview.price_mgmt = 0;
          cluster_model.instance_overview.license_mgmt = 0;
        }
        this.rawDataExceeded = true;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('ITC ERROR', e);
      }
      this.setModel(cluster_model);
    },

    switch_to_advanced_configurator() {
      this.advanced_view = true;
      const cluster_model = this.cluster_model_advanced;
      if (cluster_model.instance_family != null) {
        return;
      }
      cluster_model.budget = 'Custom';
      cluster_model.raw_data =
        cluster_model.raw_data == null || isNaN(cluster_model.raw_data) ? cluster_model.size : cluster_model.raw_data;

      try {
        cluster_model.instance_type = cluster_model.instance_overview.name;
        cluster_model.num_node = cluster_model.instance_overview.number;
        const firstInstanceFamily = Object.keys(
          sizing_selection[cluster_model.license][cluster_model.cloudplatform]['instances']
        )[0];
        this.change_instance_family(cluster_model, firstInstanceFamily);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('ITC ERROR', e);
      }
    },

    switch_to_basic_configurator() {
      this.advanced_view = false;
      this.cluster_model.budget = 'Basic';
      this.instance_type_changed(this.cluster_model, false);
    },
  },
};

(function () {
  try {
    var $imgs = document.querySelectorAll('.m39__member img');
    if ($imgs.length) {
      for (var i = 0; i < $imgs.length; ++i) {
        var $img = $imgs[i];
        var original = ($img.src || $img.getAttribute('data-src')).replace(/(-[0-9][0-9][0-9]x[0-9][0-9][0-9])/gi, '');
        $img.setAttribute('data-src', original);
        $img.setAttribute('src', original);
        $img.style['object-fit'] = 'contain';
        $img.style['height'] = 'auto';
        $img.parentNode.style['padding-bottom'] = '100%';
        $img.parentNode.style['overflow'] = 'hidden';
      }
    }

    var $btn = document.querySelector("[href='#form-row']");
    if ($btn) {
      $btn.setAttribute('target', '_self');
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('ITC ERROR', e);
  }
})();
</script>

<style scoped>
.v-stepper,
.v-stepper__header {
  box-shadow: none;
}

.wizard {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: left;
}

.top {
  height: 100%;
  margin: 0 auto;
  width: 100%;
}

.bottom {
  display: flex;
  height: 98px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
  padding: 0 20px;
}
</style>
