<template>
  <div class="col2">
    <Card
      hover
      :class="cluster_model.product === 'exasol_single_node' ? 'active' : ''"
      @click.native="changeSystem('exasol_single_node', 1)"
    >
      <h4>Exasol Single Node</h4>
      <div v-show="helper_sections.product" class="text-xs-left">
        <p>Limitations compared to cluster:</p>
        <ul>
          <li>Scale out not supported</li>
          <li>No integrated fail-over capabilities</li>
          <li>Suited for small to medium data sizes</li>
        </ul>
      </div>
    </Card>

    <Card
      hover
      :class="cluster_model.product == 'exasol_cluster' ? 'active' : ''"
      @click.native="changeSystem('exasol_cluster', 2)"
    >
      <h4>Enterprise Cluster</h4>

      <div v-show="helper_sections.product">
        <p>Unlimited scalability with enterprise support:</p>
        <ul>
          <li>Cluster for unlimited scale and high availability</li>
          <li>Efficient and fast scale-up/down and scale-out</li>
        </ul>
      </div>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/common/Card';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'System',
  components: {
    Card,
  },
  props: ['helper_sections', 'setClusterModel', 'cluster_model'],
  methods: {
    changeSystem(product, num_node) {
      // eslint-disable-next-line vue/no-mutating-props
      this.cluster_model.product = product;
      // eslint-disable-next-line vue/no-mutating-props
      this.cluster_model.num_node = num_node;
      // this.setClusterModel(cm);
    },
  },
};
</script>
