<template>
  <SectionContainer :full="full" class="summary-frame">
    <div class="options side">
      <slot />
    </div>

    <div v-if="cluster_model" class="summary-container side">
      <Summary
        :region="cluster_model.region"
        :name="cluster_model.instance_overview.name"
        :name_mgmt="cluster_model.instance_type_mgmt"
        :cpu="cluster_model.instance_overview.cpu"
        :memory="cluster_model.instance_overview.memory"
        :number="cluster_model.instance_overview.number"
        :budget="cluster_model.budget"
        :size="cluster_model.size"
        :raw_data="cluster_model.raw_data"
        :product="cluster_model.product"
        :cloudplatform="cluster_model.cloudplatform"
        :license="cluster_model.license"
        :license_price="cluster_model.instance_overview.license"
        :price="cluster_model.instance_overview.price"
        :license_price_mgmt="cluster_model.instance_overview.license_mgmt"
        :price_mgmt="cluster_model.instance_overview.price_mgmt"
      >
      </Summary>
    </div>
  </SectionContainer>
</template>

<script>
import Summary from '@/components/common/Summary';
import SectionContainer from './SectionContainer';

export default {
  name: 'SummaryFrame',
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { SectionContainer, Summary },
  props: ['full', 'cluster_model'],
};
</script>

<style scoped>
.summary-frame {
  margin: 40px auto 0;
}

@media (min-width: 960px) {
  .summary-frame {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    margin-top: 50px;
    text-align: left;
  }
  .side {
    display: flex;
    width: 50%;
    padding: 0 25px;
  }

  .options {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
  }

  .summary-container {
    padding-right: 0;
  }
}
</style>
