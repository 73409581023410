import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/components/wizard/Home';
import ComponentNotFound from '@/components/common/ComponentNotFound';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    { path: '*', name: 'ComponentNotFound', component: ComponentNotFound },
    { path: '/', alias: '/home', name: 'Home', component: Home },
    { path: '/:step-(\\d+)', alias: '/home', name: 'Home', component: Home },
    { path: '/:plaform', alias: '/home', name: 'Home', component: Home },
  ],
});
