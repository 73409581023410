<template>
  <SummaryRow :title="'Estimated costs'">
    <p>
      Compute: <b>${{ overall_instance_costs }}</b
      >/hour
    </p>
    <p>
      Storage: <b>${{ overall_storage_costs }}</b
      >/hour
    </p>
    <p>
      Exasol Software:
      <b>${{ license == 'PAYG' ? overall_license_costs : '0.00' }}</b
      >/hour
    </p>
  </SummaryRow>
</template>

<script>
import SummaryRow from './SummaryRow';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Costs',
  components: { SummaryRow },
  props: [
    'number',
    'budget',
    'size',
    'raw_data',
    'product',
    'license',
    'license_price',
    'price',
    'license_price_mgmt',
    'price_mgmt',
  ],
  data() {
    return {
      helper_section: false,
    };
  },
  computed: {
    overall_instance_costs: function () {
      let c =
        this.product == 'exasol_single_node' ? this.number * this.price : this.number * this.price + this.price_mgmt;
      c = isNaN(c) ? '-' : c.toFixed(2);
      return c;
    },
    overall_storage_costs: function () {
      let raw_data = this.budget == 'Custom' ? this.raw_data : this.size;
      let overall_storage = this.product == 'exasol_single_node' ? raw_data * 0.7 : raw_data * 1.2;
      let c = (overall_storage * 0.1 * 1000) / (24 * 30);
      c = isNaN(c) ? '-' : c.toFixed(2);
      return c;
    },
    overall_license_costs: function () {
      // software
      let c =
        this.product == 'exasol_single_node'
          ? this.number * this.license_price
          : this.number * this.license_price + this.license_price_mgmt;
      c = isNaN(c) ? '-' : c.toFixed(2);
      return c;
    },
  },
};
</script>
