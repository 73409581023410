<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <div class="col size">
      <div class="fake-panel">
        <div class="panel-header">
          <h3>Raw Data Size (in TB)</h3>

          <v-select
            v-model="cluster_model.size"
            :items="providerSizes()"
            label="Raw Data Size"
            solo
            @change="changeSize2"
          />
        </div>
      </div>
      <div class="fake-panel expanded">
        <div class="panel-header">
          <h3>System Recommendations</h3>
        </div>

        <div class="card-content">
          <div class="col recommendations">
            <p>Please select one of the preconfigurated system recommendations for your selected data size</p>

            <div class="col2">
              <Card
                hover
                :disabled="!budgetAvailable['Basic']"
                :class="cluster_model.budget == 'Basic' ? 'active' : ''"
                @click.native="selectPerformance('Basic')"
              >
                <h4>Basic Configuration</h4>

                <p class="text-xs-left">
                  Select this cost-effective configuration for use cases with moderate demands on concurrency
                </p>
              </Card>

              <Card
                hover
                :disabled="!budgetAvailable['Balanced']"
                :class="cluster_model.budget == 'Balanced' ? 'active' : ''"
                @click.native="selectPerformance('Balanced')"
              >
                <h4>Balanced Configuration</h4>

                <p class="text-xs-left">
                  Select this configuration for a balanced setup suited for most use cases with medium concurrency
                </p>
              </Card>

              <Card
                hover
                :disabled="!budgetAvailable['High Performance']"
                :class="cluster_model.budget == 'High Performance' ? 'active' : ''"
                @click.native="selectPerformance('High Performance')"
              >
                <h4>High Performance Configuration</h4>

                <p class="text-xs-left">Select this configuration for high compute power and maximum concurrency</p>
              </Card>

              <!-- <Card
                v-if="cluster_model.instance_type != null"
                hover
                :class="cluster_model.budget == 'Custom' ? 'active' : ''"
                @click.native="selectPerformance('Custom')"
              >
                <h4>
                  Custom Configuration ({{ isNaN(cluster_model.raw_data) ? '0' : cluster_model.raw_data }}
                  TB)
                </h4>

                <p class="text-xs-left">
                  This configuration has been created via the
                  <v-icon small>settings</v-icon> advanced configuration mode
                </p>
              </Card> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { budgetTypes, clusterSizes } from '../constants';
import Card from '@/components/common/Card';
import sizing_wizard_input from '@/assets/sizing_wizard_input.json';

const defaultSize = (clusterSizes.find((it) => it && it.selected) || {}).value || 5;

export default {
  name: 'ConfigBasic',
  components: {
    Card,
  },
  props: ['cluster_model', 'advanced_view', 'configIsAvailable', 'setClusterModel'],
  data() {
    return {
      clusterSizes,
      defaultSize,
      radioGroup: Number(this.cluster_model.size || defaultSize),
      panelOpen: true,
    };
  },
  computed: {
    budgetAvailable() {
      const availability = {};
      budgetTypes.forEach((type) => (availability[type] = this.configIsAvailable(this.cluster_model, type)));
      return availability;
    },
    sizesAvailable() {
      try {
        const availability = {};
        const sizes = clusterSizes.map((it) => it.value);
        const reduceNum = (n, size) =>
          typeof n === 'number' ? n : this.configIsAvailable(this.cluster_model, n, size) ? 1 : 0;

        const hasAllBudgetsAvail = (size) => {
          return budgetTypes.reduce((a, b) => {
            return reduceNum(a, size) + reduceNum(b, size);
          });
        };

        sizes.forEach((size) => (availability[size] = !!hasAllBudgetsAvail(size)));

        return availability;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
        return {};
      }
    },
  },
  methods: {
    changeSize: function (n) {
      // eslint-disable-next-line vue/no-mutating-props
      this.cluster_model.size = Number(n.value);
      this.setClusterModel(this.cluster_model);
    },
    providerSizes() {
      return clusterSizes
        .filter((n) => {
          if (!this.cluster_model.cloudplatform) {
            return true;
          }
          return sizing_wizard_input['PAYG'][n.product][n.value]['Basic'][this.cluster_model.cloudplatform].number != 0;
        })
        .map((n) => n.value);
    },
    changeSize2: function (n) {
      if (!n) return;
      try {
        // eslint-disable-next-line vue/no-mutating-props
        this.cluster_model.size = Number(n);
        this.setClusterModel(this.cluster_model);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }
    },
    selectPerformance: function (type) {
      if (!this.budgetAvailable[type]) {
        return;
      }
      const cm = JSON.parse(JSON.stringify(this.cluster_model));
      cm.budget = type;
      this.setClusterModel(cm);
    },
  },
};
</script>

<style scoped>
.basic-config {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  margin-top: 50px;
  text-align: left;
}

.recommendations p {
  margin-bottom: 30px;
}
</style>
