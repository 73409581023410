import Vue from 'vue';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify, {
  theme: {
    // primary: "#76bc1c",
    primary: '#2E8DFF',
    secondary: '#093e52',
    accent: '#c3d600',
    error: '#ff6666',
    warning: '#76bc1c',
    info: '#56565a',
    success: '#00b09b',
    exa_grey: '#bebab9',
  },
  icons: {
    iconfont: 'md',
  },
});
