<template>
  <div :class="`alert theme-${theme || ''}`">
    <h3 v-if="title">{{ title }}</h3>
    <p v-if="message">{{ message }}</p>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AlertBox',
  props: ['title', 'message', 'theme'],
};
</script>

<style scoped>
.alert {
  width: 100%;
  text-align: left;
  background: #e8f6ff;
  padding: 40px;
  border-radius: var(--border-radius);
}

.alert.theme-warning {
  background: var(--theme-warning);
}

.alert h1,
.alert h2,
.alert h3,
.alert h4 {
  margin-bottom: 0.75em;
  line-height: 1.4em;
}
</style>
